<template lang="pug">
    .page
        .box-primary.mtd-row(style='margin:0')
            .lg-030-t.sm-100
                .img-wrapper
                    img.img-logo(src="./../assets/img/logo-medclub.png")
            .lg-070-t.sm-100.b-side
                h1.text-title.ta-center VALIDAÇÃO DE DOCUMENTO
                form(v-if='! ds_hash' @submit.prevent='handleSubmit()' style='margin: 20px 0;padding: 0 1em')
                    p(style='font-size:.96em;margin-bottom:1em') Por favor, informe o código obtido na sua receita.
                    .input-field(style='display:flex')
                        input(style='width:70%;padding:.5em;border-radius:0;border:1px solid #312c66;border-top-left-radius:4px;border-bottom-left-radius:4px;'
                            placeholder='Código' v-model='ds_hash_input' @input='errorMessage = null' maxlength='6')
                        button(style='width:30%;padding:.5em;background-color:#312c66;color:#fff;border:none;border-top-right-radius:4px;border-bottom-right-radius:4px;cursor:pointer') Pesquisar
                    .error(v-if='errorMessage' style='font-size:.88em;color:#f57f6c')
                        p(v-html='errorMessage')
                div(v-else-if='!anexo' style='display:flex;justify-content:center;padding: 2em 0;width:100%')
                    div.loader <div></div><div></div><div></div>
                .result(v-else)
                    .divider
                        p <b>PACIENTE</b>
                    p <b>Nome:</b> {{ anexo.cd_paciente.nm_pessoa_fisica }}
                    p <b>Idade:</b> {{ getIdade(anexo.cd_paciente.dt_nascimento) }} anos
                    p <b>Telefone:</b> {{ fixTelefoneFormat(anexo.cd_paciente.nr_telefone) }}
                    .divider
                        p <b>ESPECIALISTA</b>
                    p <b>Nome:</b> {{ anexo.cd_especialista.nm_especialista }}
                    p <b>Conselho:</b> {{ anexo.cd_especialista.nm_conselho }} {{ anexo.cd_especialista.nr_conselho }} - {{ anexo.cd_especialista.cd_uf_conselho_ds_sigla }}
                    p(style='margin-top:1em;text-align:center')
                        a.button-anexo(:href='anexo.aq_anexo' target='_blank') Visualizar documento
</template>

<style lang="scss">
    .result {
        padding: 1em;
        p { font-size: .88em }
        .divider {
            margin: .5em 0;
            position: relative;
            p {
                background-color: #fff;
                position: relative;
                z-index: 10;
                display: inline-block;
                padding-right: 10px;
            }
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                display: block;
                width: 100%;
                height: 1px;
                background-color: #ccc;
            }
        }
        .button-anexo {
            background-color: #312c66;
            color: white;
            text-decoration: none;
            border-radius: 20px;
            font-size: .88em;
            padding: .5em 1em;
            display: inline-block;
            margin-top: 1em;
        }
    }
</style>

<script>
    import axios from 'axios'
    import moment from 'moment'
    export default {
        created () {
            if (this.$route.params.ds_hash != 0) {
                this.ds_hash = this.$route.params.ds_hash
                this.pesquisar()
            }
        },
        data () {
            return {
                ds_hash: null,
                ds_hash_input: null,
                errorMessage: null,
                anexo: null
            }
        },
        methods: {
            getIdade: (date) => {
                return moment().diff(date, 'years')
            },
            fixTelefoneFormat: (telefone) => {
                if (telefone[0] == '+')
                    telefone = telefone.substr(1)
                if (telefone.substr(0, 2) == '55')
                    telefone = telefone.substr(2)
                let regex = telefone.length == 10 ? /(\d{2})(\d{4})(\d{4})/ : /(\d{2})(\d{5})(\d{4})/
                let result = telefone.replace(regex, '($1) $2-$3')
                result = result.substr(0, result.length - 4)
                return `${ result }****`
            },
            handleSubmit () {
                if (! this.ds_hash_input) {
                    this.errorMessage = 'Informe o <b>código</b> obtido na receita'
                    return 0
                }
                if (this.ds_hash_input.length < 6) {
                    this.errorMessage = 'O <b>código</b> deve conter pelo menos 6 dígitos'
                    return 0
                }
                this.ds_hash = this.ds_hash_input
                this.pesquisar()
            },
            pesquisar () {
                const baseUrl = 'https://api.medclub.com.br/v1'
                axios.get(`${ baseUrl }/appuse/anexo/arquivo/?ds_hash=${ this.ds_hash }`).then(response => {
                    this.anexo = response.data
                }).catch(err => {
                    if (err.response.status === 400) {
                        this.ds_hash = null
                        this.errorMessage = 'Documento não encontrado para esse código'
                    }
                })
            }
        }
    }
</script>